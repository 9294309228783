import './utils/i18n';
import "./utils/cookies";
import gsap from 'gsap';
import "./utils/cookies";

import { initializeComponents } from './loadComponents';
import { Header } from '../_includes/layouts/inc/header/script/v2';
import './utils/popup';
import "./genericModules/animateText";

window.addEventListener('load', () => {
  initializeComponents(document)

  window.dispatchEvent(new CustomEvent('jsModulesInitialized'));
});
window.addEventListener('DOMContentLoaded', () => {
  const header = new Header(document.querySelector('[data-header]'));

  // const footer = Footer(document.querySelector('[data-footer]'));
});
