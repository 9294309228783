export class SecondaryNavV2 {
  _fixedNav = false;
  headerHeight = 0;

  constructor(containerElement) {
    this.container = containerElement;
    this.wrapper = containerElement.querySelector('[data-sn-wrap]');
    this.mobileTitle = containerElement.querySelector('[data-sn-title-mobile]');
    this.mobileExpandButton = this.mobileTitle.querySelector('button');
    this.desktopTitle = containerElement.querySelector('[data-sn-title]');
    this.init();
  }

  init() {
    this.addEventListeners();
    this.updateNavPosition();
  }

  set expanded(value) {
    this.container.classList.toggle('sn--expanded', value);
  }

  get expanded() {
    return this.container.classList.contains('sn--expanded');
  }

  get fixedNav() {
    return this._fixedNav;
  }

  set fixedNav(value) {
    const updating = value !== this._fixedNav;

    if (!updating) {
      return;
    }

    if (value) {
      this.container.classList.add('sn--fixed');
      this.container.style.height = `${this.wrapper.getBoundingClientRect().height}px`;
    } else {
      this.container.classList.remove('sn--fixed');
      this.container.style.removeProperty('height');
    }

    this._fixedNav = value;
  }

  get headerOffset() {
    return this._headerOffset;
  }

  set headerOffset(value) {
    if (value > 0 && this._headerOffset >= 0) {
      return;
    }

    if (value > this.headerHeight && !this.fixedNav) {
      return;
    }

    if (value >= 0) {
      value = 0;
    }

    document.body.style.setProperty('--header-offset', `${value}px`);
    this.container.style.top = `${-value}px`;
    this._headerOffset = value;
  }

  addEventListeners() {
    window.addEventListener('resize', this.updateNavPosition.bind(this));
    window.addEventListener('scroll', this.updateNavPosition.bind(this));
    this.mobileExpandButton.addEventListener('click', () => {
      this.expanded = !this.expanded;
    });
  }

  updateNavPosition() {
    this.headerHeight = parseInt(
      getComputedStyle(document.body).getPropertyValue('--header-height'),
      10,
    );
    const navTop = this.wrapper.getBoundingClientRect().top;
    const navContainerTop = this.container.getBoundingClientRect().top;

    if (navContainerTop >= 0) {
      this.fixedNav = false;
      this.headerOffset = -(this.headerHeight - navContainerTop);
    } else if (navTop < this.headerHeight + 10) {
      this.fixedNav = true;
      this.headerOffset = -(this.headerHeight - navTop);
    } 
  }
}

export default element => {
  new SecondaryNavV2(element);
};
